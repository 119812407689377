import { render, staticRenderFns } from "./index.vue?vue&type=template&id=72edf169&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreIcon: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Core/Icon/Icon.vue').default,AsideTrendsItem: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Aside/Trends/Item.vue').default,UiAsideCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Ui/Aside/Card.vue').default})
