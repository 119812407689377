
const LIMIT = 10
export default {
  name: 'AsideTrends',

  data() {
    return {
      posts: [],
      currentItem: 'article',
      isLoad: false,
    }
  },

  async fetch() {
    this.isLoad = true
    await this.fetchData()
    this.isLoad = false
  },
  methods: {
    async fetchData() {
      this.isLoad = true
      if (this.currentItem === 'broadcast') {
        const { advertisements } = await this.$axios.$get(
          '/advertisements/trend',
          {
            params: {
              type: 'BROADCAST',
              period: 'month',
              limit: LIMIT,
              offset: 0,
            },
          }
        )
        this.posts = advertisements
      } else {
        const { materials } = await this.$axios.$get('/articles/trend', {
          params: {
            type: this.currentItem,
            period: 'month',
            limit: LIMIT,
            offset: 0,
          },
        })
        this.posts = materials
      }

      this.isLoad = false
    },
    setCurrentItem(item) {
      this.currentItem = item
      this.clearData()
      this.fetchData()
    },
    clearData() {
      this.posts = []
    },
  },
}
