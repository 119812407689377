
export default {
  name: 'AsideTrendsItem',
  props: {
    type: {
      type: String,
      default: 'article',
    },
    post: {
      type: Object,
      default: null,
    },
  },
  computed: {
    title() {
      if (this.post?.title) {
        return this.post.title
      } else if (this.post?.description) {
        const sanitizeText = this.post.description
          .replace(/<(.|\n)*?>/g, '')
          .replace(/&nbsp;/g, ' ')
          .replace(/[^a-zA-Z0-9а-яА-ЯёЁ .,«»"'-+=_\-/—?!:;%]/gu, '')
        return sanitizeText
      }
      return ''
    },
    telegramChannel() {
      const channelLinkArr = this.post.source.split('/')
      const channel = channelLinkArr[channelLinkArr.length - 1]
      return `${channel}`
    },
  },
  methods: {
    inView({ isInView }) {
      if (isInView && !this.isSetShow && !this.draft) {
        this.setShow()
      }
    },
    setShow() {
      if (this.type === 'broadcast') {
        this.$axios.$post('/activity/show/advertisement', {
          username: this.post.author.username,
          advertisementId: this.post._id,
        })
      } else {
        this.$axios.$post('/activity/show/article', {
          username: this.post.author.username,
          articleId: this.post._id,
        })
      }
      this.isSetShow = true
    },
  },
}
